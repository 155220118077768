import jQuery from 'jquery/dist/jquery';
import Utils from '../../../../utils'
import DefaultController from "../../../defaultController";
import Calendar from '@event-calendar/core';
import DayGrid from '@event-calendar/day-grid';
import TimeGrid from '@event-calendar/time-grid';

export default class ReportingAppointmentList extends DefaultController {

    private ulUserList: any;
    private calendars: any = {}
    private calendarResources: any[] = [];

    protected calendarAppointmentList: any = {}

    async init() {
        this.ulUserList = jQuery('#ticket_service_user_list')

        await this.setup();
        await super.init()
    }

    async setup() {
        this.calendarResources = [];
        this.calendarAppointmentList = {};
        const users = await Utils.entity.getAll("users/capable_users")
        users.data.forEach((u: any, i: number) => {
            this.calendarResources.push({
                id: i + 1,
                title: u.name,
                color: `#${Utils.genCalendarColor(i)}`,
                uuid: u.uuid
            })
        })

        this.ulUserList.html("");
        for (const resource of this.calendarResources) {
            this.calendarAppointmentList[resource.uuid] = [];
            this.ulUserList.html(this.ulUserList.html() + `<li data-resource-id='${resource.id}' data-resource-uuid='${resource.uuid}' data-resource-name='${resource.title}' data-color='${resource.color}' class="list-group-item d-flex justify-content-between align-items-start"> <div class="ms-2 me-auto"> <div class="fw-bold">${resource.title}</div> </div> <span class="badge rounded-pill" style="background-color:${resource.color};">&nbsp;</span> </li>`)
            const e = await Utils.entity.get(resource.uuid, "users")
            if (e.status === 200) {
                const userData = e.data[0]
                const appointments = userData.calendarAppointments;
                for (const appointment of appointments) {
                    let title = appointment.title
                    if (appointment.external) {
                        title = `[EXTERN]${title}`
                    }
                    this.calendarAppointmentList[resource.uuid].push({
                        id: appointment.uuid,
                        start: appointment.from,
                        end: appointment.to,
                        resourceId: resource.id,
                        title: title,
                        color: resource.color,
                        editable: !appointment.external,
                        external: appointment.external
                    })
                }
            }
        }

        if (document.querySelector("#ticket_service_calendar")) {
            (document.querySelector("#ticket_service_calendar") as HTMLElement).innerHTML = "";
        }
    }
    
    getEntityData(elem: any) {
        return {}
    }
    bindListeners() {
        this.ulUserList.delegate("li", "click", (e: any) => {
            this.onUserClick(e);
        });
        (document.querySelector("#ticket_service_calendar_picker") as HTMLButtonElement).addEventListener("change", async (e) => {
            e.preventDefault();
            const calendarIds = Object.keys(this.calendars);
            calendarIds.forEach((calendarId: string) => {
                const calendar = this.calendars[calendarId];
                let oldDate = (document.querySelector("#ticket_service_calendar_picker") as HTMLInputElement).valueAsDate;
                if (oldDate) {
                    calendar.setOption('date', oldDate);
                    const header = document.querySelector("#ticket_service_calendar_date");
                    if (header) {
                        header.innerHTML = `<h3>${oldDate.toLocaleDateString("de-DE", {month: "2-digit", year: "numeric", day: "2-digit"})}</h3>`
                    }
                }
            })
        });
        (document.querySelector("#ticket_service_calendar_arrows_prev") as HTMLButtonElement).addEventListener("click", async (e) => {
            e.preventDefault();
            const calendarIds = Object.keys(this.calendars);
            calendarIds.forEach((calendarId: string) => {
                const calendar = this.calendars[calendarId];
                let oldDate = calendar.getOption('date');
                oldDate.setDate(oldDate.getDate() - 1)
                calendar.setOption('date', oldDate);
                const header = document.querySelector("#ticket_service_calendar_date");
                if (header) {
                    header.innerHTML = `<h3>${oldDate.toLocaleDateString("de-DE", {month: "2-digit", year: "numeric", day: "2-digit"})}</h3>`
                }
                oldDate.setDate(oldDate.getDate() + 1);
                (document.querySelector("#ticket_service_calendar_picker") as HTMLInputElement).value = oldDate.toISOString().substr(0, 10);
            })
        });
        (document.querySelector("#ticket_service_calendar_arrows_next") as HTMLButtonElement).addEventListener("click", async (e) => {
            e.preventDefault();
            const calendarIds = Object.keys(this.calendars);
            calendarIds.forEach((calendarId: string) => {
                const calendar = this.calendars[calendarId];
                let oldDate = calendar.getOption('date');
                oldDate.setDate(oldDate.getDate() + 1)
                calendar.setOption('date', oldDate);
                const header = document.querySelector("#ticket_service_calendar_date");
                if (header) {
                    header.innerHTML = `<h3>${oldDate.toLocaleDateString("de-DE", {month: "2-digit", year: "numeric", day: "2-digit"})}</h3>`
                }
                oldDate.setDate(oldDate.getDate() + 1);
                (document.querySelector("#ticket_service_calendar_picker") as HTMLInputElement).value = oldDate.toISOString().substr(0, 10);
            })
        });
    }
    createUserCalendar(element: any, userUuid: string, userTitle: string, userColor: string) {
        const calendar = new Calendar({
            target: element,
            props: {
                plugins: [DayGrid, TimeGrid],
                options: {
                    view: 'timeGridDay',
                    views: {timeGridDay: {pointer: true}},
                    resources: [{id: 0, title: userTitle, color: userColor, uuid: userUuid}],
                    scrollTime: '09:00:00',
                    allDaySlot: false,
                    events: [],
                    nowIndicator: true,
                    selectable: true,
                    selectBackgroundColor: userColor,
                    firstDay: 1,
                    headerToolbar: {
                        start: "",
                        center: ``,
                        end: ""
                    },
                    eventDrop: async (info: any) => {
                    },
                    eventClick: (info: any) => {
                    },
                    eventResize: async (info: any) => {
                    },
                    select: async (info: any) => {

                    }
                }
            }
        });

        const events = this.calendarAppointmentList[userUuid]
        if (events) {
            events.forEach((appointment: any) => {
                if (calendar) {
                    let title = appointment.title
                    if (appointment.external) {
                        title = `[EXTERN]${title}`
                    }
                    calendar.addEvent({id: appointment.id, start: appointment.start, end: appointment.end, resourceId: 0, title: title, color: userColor, editable: appointment.editable, extendedProps: {external: appointment.external}})
                }
            })
        }
        this.calendars[userUuid] = calendar;


        const oldDate = new Date();
        const header = document.querySelector("#ticket_service_calendar_date");
        if (header) {
            header.innerHTML = `<h3>${oldDate.toLocaleDateString("de-DE", {month: "2-digit", year: "numeric", day: "2-digit"})}</h3>`
        }
    }

    onUserClick(e: any) {
        const currentTarget = e.currentTarget;
        const resourceId = currentTarget.getAttribute("data-resource-id") as string;
        const uuid = currentTarget.getAttribute("data-resource-uuid") as string;
        const name = currentTarget.getAttribute("data-resource-name") as string ;
        const color = currentTarget.getAttribute("data-color") as string;
        const parent = document.querySelector("#ticket_service_calendars") as HTMLElement;

        const currentIds = Object.keys(this.calendars)
        if (this.calendars[uuid]) {
            this.calendars[uuid].destroy();
            parent.removeChild((document.querySelector(`#column_${uuid}`) as HTMLElement));
            delete this.calendars[uuid];
            currentTarget.classList.remove("active");
        } else if (currentIds.length <= 5) {
            const parentElem = document.createElement('div');
            parentElem.classList.add("col-2");
            parentElem.id = `column_${uuid}`
            const row = document.createElement('div');
            row.classList.add("row");
            row.innerHTML = `<div class="col-12 text-center"><b>${name}</b></div>`;

            const rowCalendar = document.createElement('div');
            rowCalendar.classList.add("row");
            this.createUserCalendar(rowCalendar, uuid, name, color)

            parentElem.appendChild(row);
            parentElem.appendChild(rowCalendar);

            parent.appendChild(parentElem);
            currentTarget.classList.add("active");
        }
        console.log("this.calendars", this.calendars);
    }
}